import Header from "../../components/header/Header";
import AccountBar from "../../components/accountbar/AccountBar";

const Navbar = (props) => {
  return (
    <>
      <div className="mb-16">
        <div className="header-wrap">
          <div className="title-bar">
            <Header title={props.title} />
          </div>
          <div className="user-bar">
            <AccountBar />
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
