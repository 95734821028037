import React, { useEffect, useRef, useState } from "react";
import { useFormik } from "formik";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";
import { classNames } from "primereact/utils";
import { Password } from "primereact/password";
import axios from "axios";
import { Navigate, Link } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import Swal from "sweetalert2";

export default function RegisterForm() {
  const toast = useRef(null);
  const [redirect, setRedirect] = useState(false);
  const [dataBidang, setDataBidang] = useState(null);
  const [selectedBidang, setSelectedBidang] = useState([]);

  useEffect(() => {
    setRedirect(false);
    axios.get("https://bendmitra.ptpema.co.id/api/register").then((resp) => {
      setDataBidang(resp.data);
    });
  }, [redirect]);

  const onBidangChange = (e) => {
    let _bidang = [];
    _bidang.push(e.value);

    _bidang = _bidang.flat(1);
    formik.setFieldValue("bidang", _bidang);

    setSelectedBidang(e.value.flat(1));
  };

  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      no_hp: "",
      alamat: "",
      password: "",
      confirmPassword: "",
      bidang: [],
    },
    validate: (data) => {
      let errors = {};
      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (!data.name) {
        errors.name = "Company Name is required.";
      }

      if (!data.email) {
        errors.email = "Email is required.";
      }

      if (!validRegex.test(data.email)) {
        errors.email = "Email is not valid.";
      }

      if (!data.no_hp) {
        errors.no_hp = "Phone Number is required.";
      }

      if (!data.alamat) {
        errors.alamat = "Address is required.";
      }

      if (data.bidang.length === 0) {
        errors.bidang = "At least one Type of Business is required.";
      }

      if (!data.password) {
        errors.password = "Password is required.";
      }

      if (data.password.length < 8) {
        errors.password = "Password must be at least 8 characters long.";
      }

      if (!data.confirmPassword) {
        errors.confirmPassword = "Confirm password is required.";
      }

      if (data.password !== data.confirmPassword) {
        errors.confirmPassword = "Password and Confirm Password must be same.";
      }

      return errors;
    },
    onSubmit: (data) => {
      delete data.confirmPassword;
      Swal.fire({
        title: "Loading...",
        timer: 10000,
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .post("https://bendmitra.ptpema.co.id/api/register", data)
        .then((resp) => {
          localStorage.setItem("cache-t", resp.data.token);
          localStorage.setItem("email", data.email);
          setRedirect(true);
          formik.resetForm();
          toast.current.show({ severity: "success", summary: "Successful", detail: "Account Created", life: 3000 });
        })
        .catch((err) => {
          toast.current.show({ severity: "error", summary: "Error", detail: err.response.data.message, life: 3000 });
        });
      setSelectedBidang([]);
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  return (
    <div className="flex flex-col justify-center">
      {redirect && <Navigate to="/verify" />}
      <Toast ref={toast} />
      <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 mx-12 mt-2">
        <span className="p-float-label">
          <InputText
            id="name"
            name="name"
            style={{ width: "100%" }}
            value={formik.values.name}
            onChange={(e) => {
              formik.setFieldValue("name", e.target.value);
            }}
            className={classNames({ "p-invalid": isFormFieldInvalid("name") })}
          />
          <label htmlFor="name">Company Name</label>
        </span>
        {getFormErrorMessage("name")}

        <span className="p-float-label">
          <InputText
            id="email"
            name="email"
            style={{ width: "100%" }}
            value={formik.values.email}
            onChange={(e) => {
              formik.setFieldValue("email", e.target.value);
            }}
            className={classNames({ "p-invalid": isFormFieldInvalid("email") })}
            keyfilter={/^[^\s]+$/}
          />
          <label htmlFor="email">Email</label>
        </span>
        {getFormErrorMessage("email")}

        <span className="p-float-label">
          <MultiSelect
            value={selectedBidang}
            options={dataBidang}
            style={{ width: "100%" }}
            onChange={(e) => {
              onBidangChange(e);
            }}
            optionLabel="nama_bidang_usaha"
            maxSelectedLabels={3}
            className={classNames({ "p-invalid": isFormFieldInvalid("bidang") })}
          />
          <label htmlFor="bidang">Types of Business</label>
        </span>
        {getFormErrorMessage("bidang")}

        <span className="p-float-label">
          <InputText
            id="no_hp"
            name="no_hp"
            style={{ width: "100%" }}
            value={formik.values.no_hp}
            onChange={(e) => {
              formik.setFieldValue("no_hp", e.target.value);
            }}
            keyfilter="int"
            className={classNames({ "p-invalid": isFormFieldInvalid("no_hp") })}
          />
          <label htmlFor="no_hp">Phone Number</label>
        </span>
        {getFormErrorMessage("no_hp")}

        <span className="p-float-label">
          <InputText
            id="alamat"
            name="alamat"
            style={{ width: "100%" }}
            value={formik.values.alamat}
            onChange={(e) => {
              formik.setFieldValue("alamat", e.target.value);
            }}
            className={classNames({ "p-invalid": isFormFieldInvalid("alamat") })}
          />
          <label htmlFor="alamat">Address</label>
        </span>
        {getFormErrorMessage("alamat")}

        <span className="p-float-label">
          <Password
            id="password"
            name="password"
            inputStyle={{ width: "18rem" }}
            value={formik.values.password}
            onChange={(e) => {
              formik.setFieldValue("password", e.target.value);
            }}
            className={classNames({ "p-invalid": isFormFieldInvalid("password") })}
            toggleMask
          />

          <label htmlFor="password">Password</label>
        </span>

        {getFormErrorMessage("password")}

        <span className="p-float-label">
          <Password
            id="confirmPassword"
            name="confirmPassword"
            value={formik.values.confirmPassword}
            inputStyle={{ width: "18rem" }}
            onChange={(e) => {
              formik.setFieldValue("confirmPassword", e.target.value);
            }}
            className={classNames({ "p-invalid": isFormFieldInvalid("confirmPassword") })}
            toggleMask
            feedback={false}
          />
          <label htmlFor="confirmPassword">Confirm Password</label>
        </span>
        {getFormErrorMessage("confirmPassword")}

        <Button type="submit" label="Sign Up" />
        <b className="divider">OR</b>
        <Link to="/login" className="text-blue-500">
          <Button label="Login" className="w-full" />
        </Link>
      </form>
    </div>
  );
}
