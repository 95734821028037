import React, { useState, useRef } from "react";
import { Tag } from "primereact/tag";
import { Button } from "primereact/button";
import { Toast } from "primereact/toast";

const FileUpload = (props) => {
  const wrapperRef = useRef(null);
  const [fileList, setFileList] = useState([]);
  const toast = useRef(null);

  const onDragEnter = () => {
    wrapperRef.current.classList.add("opacity-60");
  };

  const onDragLeave = () => {
    wrapperRef.current.classList.remove("opacity-60");
  };

  const onDrop = () => {
    wrapperRef.current.classList.remove("opacity-60");
  };

  const onFileDrop = (e) => {
    const newFile = e.target.files[0];
    if (newFile.size > 25000000) {
      toast.current.show({ severity: "error", summary: "File too large", detail: "File size must be less than 25 MB", life: 10000 });
      return;
    }
    if (newFile) {
      setFileList([newFile]);
      props.onFileChange(props.name, newFile);
    }
  };

  const fileRemove = () => {
    setFileList([]);
    props.onFileChange(props.name, null);
  };

  return (
    <>
      <Toast ref={toast} />

      {fileList[0] ? (
        <>
          {
            <div className="flex flex-wrap justify-between px-8 my-8">
              <div className="flex items-center">
                <i className="pi pi-file-pdf" style={{ fontSize: "4rem", color: "red" }}></i>
                <span className="flex flex-col text-left ml-3">
                  <p>{fileList[0].name}</p>
                  <small>{new Date().toLocaleDateString("en-GB")}</small>
                </span>
              </div>
              <div className="flex items-center gap-4">
                <Tag value={fileList[0].size / 1000 + " KB"} severity="warning" className="px-3 py-2" />
                <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger ml-auto" onClick={() => fileRemove()} />
              </div>
            </div>
          }
        </>
      ) : (
        <div ref={wrapperRef} onDragEnter={onDragEnter} onDragLeave={onDragLeave} onDrop={onDrop} className="flex items-center justify-center relative border-2 border-dashed border-slate-300 hover:opacity-60 rounded-md h-72">
          <div className="flex flex-col items-center">
            <i className="pi pi-file-pdf p-5 mt-8 bg-slate-100" style={{ fontSize: "5em", borderRadius: "50%", color: "var(--surface-d)" }}></i>
            <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
              Drag and Drop Document Here
            </span>
          </div>
          <input type="file" accept="application/pdf" className="w-full h-full absolute opacity-0 cursor-pointer" required onChange={onFileDrop} />
        </div>
      )}
    </>
  );
};

export default FileUpload;
