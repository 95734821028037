import { Navigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Documents from "../../components/fileupload/Documents";
import { useState, useRef } from "react";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import axios from "axios";

const Profile = () => {
  const accessToken = localStorage.getItem("access_token");
  const [visible, setVisible] = useState(false);
  const [profilePicture, setProfilePicture] = useState(localStorage.getItem("profile_picture"));
  const [file, setFile] = useState({});
  const toast = useRef(null);
  const [edit, setEdit] = useState(false);
  const [data, setData] = useState({
    name: localStorage.getItem("nameUser"),
    email: localStorage.getItem("email"),
    no_hp: localStorage.getItem("no_hp"),
    alamat: localStorage.getItem("alamat"),
  });
  let files = [];

  if (localStorage.getItem("role") !== "Mitra" && accessToken) {
    return <Navigate to="/admin" />;
  }

  if (!accessToken) {
    return <Navigate to="/login" />;
  }

  const onFileChange = (name, data) => {
    if (name === null) {
      files.splice(files.indexOf(data), 1);
    } else {
      files.push(data);
      let _data = { ...file };
      _data[`${name}`] = data;
      setFile(_data);
    }
  };

  const openDialog = () => {
    setVisible(true);
  };

  const cancelSubmit = () => {
    setVisible(false);
    setFile(null);
  };

  const submit = () => {
    axios
      .post("https://bendmitra.ptpema.co.id/api/profile/", file, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setVisible(false);
        setFile(null);
        axios
          .get("https://bendmitra.ptpema.co.id/api/profile/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((resp) => {
            localStorage.setItem("profile_picture", resp.data.profile_picture);
            setProfilePicture(resp.data.profile_picture);
          });

        toast.current.show({ severity: "success", summary: "Successful", detail: "Profile Changed", life: 3000 });
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Profile Not Changed", life: 3000 });
      });
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={cancelSubmit} />
      <Button label="Submit" icon="pi pi-check" onClick={submit} />
    </div>
  );

  // Edit data profile

  const handleSubmit = (e) => {
    e.preventDefault();
    axios
      .post("https://bendmitra.ptpema.co.id/api/profile/", data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(() => {
        axios
          .get("https://bendmitra.ptpema.co.id/api/profile/", {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then((resp) => {
            setData(resp.data);
          });
        localStorage.setItem("nameUser", data.name);
        localStorage.setItem("no_hp", data.no_hp);
        localStorage.setItem("alamat", data.alamat);
        setEdit(false);
        toast.current.show({ severity: "success", summary: "Successful", detail: "Profile Changed", life: 3000 });
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Profile Not Changed", life: 3000 });
      });
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _data = { ...data };
    _data[`${name}`] = val;
    setData(_data);
  };

  return (
    <div className="container">
      <Toast ref={toast} />
      <Sidebar />
      <main>
        <Navbar title="Profile" />
        <div className="content">
          <div className="content-form">
            <div className="flex justify-center mb-4">
              <img src={profilePicture} alt="profile" className="w-48 h-48 rounded-full" />
              <div className="relative self-end right-16">
                <Button icon="pi pi-pencil" rounded size="small" onClick={openDialog} />
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className="form-container">
                <div className="form-item">
                  <label htmlFor="nama">Nama</label>
                  <div>
                    {edit && (
                      <input
                        className="input border border-solid input-bordered w-full"
                        type="text"
                        id="nama"
                        value={data.name}
                        onChange={(e) => {
                          onInputChange(e, "name");
                        }}
                        autoFocus
                      />
                    )}
                    {!edit && <input className="input border border-solid input-bordered w-full" type="text" id="nama" value={data.name} readOnly />}
                  </div>
                </div>
                <div className="form-item">
                  <label htmlFor="email">Email</label>
                  <div>
                    <input className="input border border-solid input-bordered w-full" type="text" id="email" value={data.email} readOnly />
                  </div>
                </div>
                <div className="form-item">
                  <label htmlFor="nomor_hp">Nomor HP</label>
                  <div>
                    {edit && (
                      <input
                        type="text"
                        placeholder=""
                        className="input border border-solid input-bordered w-full"
                        id="nomor_hp"
                        value={data.no_hp}
                        onChange={(e) => {
                          onInputChange(e, "no_hp");
                        }}
                      />
                    )}
                    {!edit && <input type="text" placeholder="" className="input border border-solid input-bordered w-full" id="nomor_hp" value={data.no_hp} readOnly />}
                  </div>
                </div>
                <div className="form-item">
                  <label htmlFor="alamat">Alamat</label>
                  <div>
                    {edit && (
                      <input
                        type="text"
                        placeholder=""
                        className="input border border-solid input-bordered w-full"
                        id="alamat"
                        value={data.alamat}
                        onChange={(e) => {
                          onInputChange(e, "alamat");
                        }}
                      />
                    )}
                    {!edit && <input type="text" placeholder="" className="input border border-solid input-bordered w-full" id="alamat" value={data.alamat} readOnly />}
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-3">
                {!edit && (
                  <Button
                    label="Edit"
                    onClick={() => {
                      setEdit(true);
                    }}
                    severity="info"
                  />
                )}
                {edit && (
                  <div className=" flex gap-3">
                    <Button
                      label="Cancel"
                      severity="danger"
                      onClick={() => {
                        setEdit(false);
                      }}
                      icon="pi pi-times"
                      size="small"
                    />
                    <Button label="Save" type="submit" severity="success" icon="pi pi-check" size="small" />
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <Dialog visible={visible} draggable={false} style={{ width: "50vw" }} onHide={() => setVisible(false)} footer={footerContent}>
          <Documents name="photo" multiple={false} onFileChange={(name, files) => onFileChange(name, files)} listFiles={files} />
        </Dialog>
      </main>
    </div>
  );
};
export default Profile;
