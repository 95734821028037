import Logo from "../../assets/logo.png";
import { Navigate, useParams, useSearchParams } from "react-router-dom";
import { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { Password } from "primereact/password";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";

const ResetPassword = () => {
  const [redirect, setRedirect] = useState(false);
  const toast = useRef(null);
  const token = useParams().token;
  const [email] = useSearchParams();

  const formik = useFormik({
    initialValues: {
      token: "",
      email: "",
      password: "",
      password_confirmation: "",
    },
    validate: (data) => {
      let errors = {};

      if (!data.password) {
        errors.password = "Password is required.";
      }

      if (data.password.length < 8) {
        errors.password = "Password must be at least 8 characters long.";
      }

      if (!data.password_confirmation) {
        errors.password_confirmation = "Confirm password is required.";
      }

      if (data.password !== data.password_confirmation) {
        errors.password_confirmation = "Password and Confirm Password must be same.";
      }

      return errors;
    },
    onSubmit: (data) => {
      data.token = token;
      data.email = email.get("email");
      Swal.fire({
        title: "Loading...",
        timer: 2000,
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .post("https://bendmitra.ptpema.co.id/api/reset-password", data)
        .then(() => {
          toast.current.show({ severity: "success", summary: "Success", detail: "Your Password has been successfully changed!", life: 3000 });
          setRedirect(true);
        })
        .catch((err) => {
          toast.current.show({ severity: "error", summary: "Failed to change password, try again!", detail: err.response.data.message, life: 5000 });
        });
      formik.resetForm();
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  return (
    <>
      {redirect && <Navigate to="/login" />}
      <Toast ref={toast} />
      <div className="login">
        <div className="login-card">
          <img src={Logo} alt="logo" />
          <div className="lc-title text-center">
            <h2 className="font-bold text-lg">Reset Password!</h2>
          </div>
          <div className="login-form flex flex-col justify-center">
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 mt-2">
              <span className="p-float-label">
                <Password
                  id="password"
                  name="password"
                  inputStyle={{ width: "21rem" }}
                  value={formik.values.password}
                  onChange={(e) => {
                    formik.setFieldValue("password", e.target.value);
                  }}
                  className={classNames({ "p-invalid": isFormFieldInvalid("password") })}
                  toggleMask
                />

                <label htmlFor="password">Password</label>
              </span>

              {getFormErrorMessage("password")}

              <span className="p-float-label">
                <Password
                  id="password_confirmation"
                  name="password_confirmation"
                  value={formik.values.password_confirmation}
                  inputStyle={{ width: "21rem" }}
                  onChange={(e) => {
                    formik.setFieldValue("password_confirmation", e.target.value);
                  }}
                  className={classNames({ "p-invalid": isFormFieldInvalid("password_confirmation") })}
                  toggleMask
                  feedback={false}
                />
                <label htmlFor="password_confirmation">Confirm Password</label>
              </span>
              {getFormErrorMessage("password_confirmation")}

              <Button type="submit" label="Reset" />
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
