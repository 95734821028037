import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Navigate } from "react-router-dom";
import ViewData from "../../components/dataview/DataView";

const Histroy = () => {
  const accessToken = localStorage.getItem("access_token");
  const urlKerjasama = "https://bendmitra.ptpema.co.id/api/kerjasama";

  if (localStorage.getItem("role") !== "Mitra" && accessToken) {
    return <Navigate to="/admin" />;
  }
  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="container">
      <Sidebar />
      <main>
        <Navbar title="Projects History" />
        <div className="content">
          <ViewData urlProject={urlKerjasama} urlKerjasama={urlKerjasama} history={true} />
        </div>
      </main>
    </div>
  );
};

export default Histroy;
