import React from "react";
import { saveAs } from "file-saver";
import { Button } from "primereact/button";
import axios from "axios";

const DownloadFile = (props) => {
  const download = (data, fileName) => {
    axios
      .get(`https://bendmitra.ptpema.co.id/api/syarat/download/${data["id_user"]}/${data[fileName]}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
        responseType: "blob",
      })
      .then((resp) => {
        let pdf = new Blob([resp.data], { type: "application/pdf" });
        saveAs(pdf, data[fileName]);
      })
      .catch(() => {});
  };
  return (
    <>
      <div className="flex flex-wrap justify-between px-8 my-8">
        <div className="flex items-center">
          <i className="pi pi-file-pdf" style={{ fontSize: "4rem", color: "red" }}></i>
          <span className="flex flex-col text-left ml-3">
            <p>{props.data[props.fileName]}</p>
            <small>{props.fileDate}</small>
          </span>
        </div>
        <div className="flex items-center gap-2">
          <Button type="button" icon="pi pi-download" className="p-button-outlined p-button-rounded p-button-info" onClick={() => download(props.data, props.fileName)} />
        </div>
      </div>
    </>
  );
};

export default DownloadFile;
