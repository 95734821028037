import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import React, { useState, useEffect, useRef } from "react";
import Table from "../../components/datatable/DataTable";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Navigate } from "react-router-dom";

const Direksi = () => {
  const accessToken = localStorage.getItem("access_token");
  const [dataDireksi, setDataDireksi] = useState([]);
  const [submitted, setSubmitted] = useState(false);
  const toast = useRef(null);
  const url = "https://bendmitra.ptpema.co.id/api/direksi";

  const columns = [
    { field: "nama", header: "Name" },
    { field: "posisi", header: "Position" },
    { field: "no_hp", header: "Phone Number" },
  ];

  useEffect(() => {
    axios
      .get(`${url}`, {
        headers: {
          Accept: "application/json",
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => {
        setDataDireksi(resp.data);
        setSubmitted(false);
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetch Data", life: 3000 });
      });
    // eslint-disable-next-line
  }, [submitted]);

  if (localStorage.getItem("role") !== "Mitra") {
    return <Navigate to="/admin" />;
  }
  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }

  const onSubmited = () => {
    setSubmitted(true);
  };

  return (
    <div className="container">
      <Toast ref={toast} />
      <Sidebar />
      <main>
        <Navbar title="Director" />
        <div className="content">
          <div className="task-list">
            <Table data={dataDireksi} id={"id_director"} columns={columns} action={true} edit={true} url={url} onSubmited={() => onSubmited()}></Table>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Direksi;
