import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Navigate, useParams } from "react-router-dom";
import { useEffect, useState, useRef } from "react";
import axios from "axios";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import Swal from "sweetalert2";

const ProjectDetail = () => {
  const accessToken = localStorage.getItem("access_token");
  const params = useParams();
  const toast = useRef(null);
  const [listMitra, setListMitra] = useState([]);
  const [visible, setVisible] = useState(false);
  const [data, setData] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [selectedMitra, setSelectedMitra] = useState(null);
  const url = "https://bendmitra.ptpema.co.id/api/kerjasama";
  const urlProject = "https://bendmitra.ptpema.co.id/api/project";
  const [totalValues, setTotalValues] = useState({
    pema: 0,
    mitra: 0,
  });
  const dataPenilaian = [
    { name: "Human Resources", id: "sdm", pemaValue: null, mitraValue: null },
    { name: "Technology", id: "teknologi", pemaValue: null, mitraValue: null },
    { name: "Capital", id: "modal", pemaValue: null, mitraValue: null },
    { name: "Asset", id: "aset", pemaValue: null, mitraValue: null },
    { name: "Networking", id: "networking", pemaValue: null, mitraValue: null },
    { name: "Collaboration Interests", id: "minat_kerjasama", pemaValue: null, mitraValue: null },
  ];

  useEffect(() => {
    setRefresh(false);
    axios
      .get(`${url}/${params.idProject}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((response) => {
        if (response.data.length > 0) {
          setListMitra(response.data);
        } else {
          setListMitra(null);
        }
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetch Data", life: 3000 });
      });
    axios
      .get(`${urlProject}/${params.idProject}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((resp) => {
        dataPenilaian.map((item) => (item.pemaValue = resp.data[0][item.id] !== "0" && resp.data[0][item.id] !== 0 ? resp.data[0][item.id] : "-"));
        setData(dataPenilaian);
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetch Data", life: 3000 });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  if (localStorage.getItem("role") !== "Admin") {
    return <Navigate to="/" />;
  }

  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }

  const lihatMitra = (rowData) => {
    const _data = [...data];
    _data.map((item) => (item.mitraValue = rowData[item.id] !== "0" && rowData[item.id] !== 0 ? rowData[item.id] : "-"));

    hitungPersentase(_data);
    setData(_data);
    setSelectedMitra(rowData);
    setVisible(true);
  };

  const hitungPersentase = (datas) => {
    let _totalValues = { ...totalValues };
    // eslint-disable-next-line
    datas.map((item) => {
      if (item.mitraValue !== "-" && item.mitraValue !== null && item.id !== "minat_kerjasama") {
        if (item.pemaValue >= item.mitraValue) {
          _totalValues.mitra = _totalValues.mitra + 0.5;
        } else {
          _totalValues.mitra++;
        }
      }
      if (item.pemaValue !== "-" && item.pemaValue !== null && item.id !== "minat_kerjasama") {
        if (item.mitraValue >= item.pemaValue) {
          _totalValues.pema = _totalValues.pema + 0.5;
        } else {
          _totalValues.pema++;
        }
      }
    });

    // Hitung persentase
    _totalValues.pema = _totalValues.pema * 20;
    _totalValues.mitra = _totalValues.mitra * 20;
    setTotalValues(_totalValues);
  };

  const icon = (item, id) => {
    if (id === "modal" || id === "aset") {
      return item;
    } else if (id === "minat_kerjasama") {
      return item === null || item === "" ? "-" : item;
    }

    if (item !== "-") {
      return <i className="pi pi-check"></i>;
    }

    return <i className="pi pi-times"></i>;
  };

  const actionMitra = (rowData) => {
    return (
      <div className="flex justify-center">
        <Button label="See Partners" className="h-12" severity="info" size="small" onClick={() => lihatMitra(rowData)} />
      </div>
    );
  };

  const closeDialog = () => {
    setTotalValues({
      pema: 0,
      mitra: 0,
    });
    setVisible(false);
  };

  const terimaHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will agree to the cooperation of this partner",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      let _data = {
        ...selectedMitra,
        message: "ACCEPTED",
      };
      if (result.isConfirmed) {
        axios
          .post(`${url}/${params.idProject}`, _data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then(() => {
            toast.current.show({ severity: "success", summary: "Success", detail: "Successfully accepted the offer" });
          })
          .catch(() => {
            toast.current.show({ severity: "error", summary: "Failed", detail: "Failed to accept offer" });
          });
      }
      setRefresh(true);
    });
    setTotalValues({
      pema: 0,
      mitra: 0,
    });
    setSelectedMitra(null);
    setVisible(false);
  };

  const tolakHandler = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will refuse cooperation from this partner",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      let _data = {
        ...selectedMitra,
        message: "REJECTED",
      };
      if (result.isConfirmed) {
        axios
          .post(`${url}/${params.idProject}`, _data, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("access_token")}`,
            },
          })
          .then(() => {
            toast.current.show({ severity: "success", summary: "Success", detail: "Successfully accepted the offer" });
          })
          .catch(() => {
            toast.current.show({ severity: "error", summary: "Failed", detail: "Failed to accept offer" });
          });
      }
      setRefresh(true);
    });
    setTotalValues({
      pema: 0,
      mitra: 0,
    });
    setSelectedMitra(null);
    setVisible(false);
  };

  const footerContent = (
    <div>
      <Button label="Reject" icon="pi pi-times" severity="danger" onClick={tolakHandler} />
      <Button label="Accept" icon="pi pi-check" onClick={terimaHandler} />
    </div>
  );

  if (localStorage.getItem("role") !== "Admin") {
    return <Navigate to="/" />;
  }
  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <Toast ref={toast} />
      <Sidebar />
      <main>
        <Navbar />
        <div className="content">
          <div className="task-list">
            <DataTable value={listMitra} showGridlines tableStyle={{ minWidth: "5rem" }}>
              <Column field="name" header="Company Name"></Column>
              <Column field="status_pengajuan" header="Submission Status" exportable={false} style={{ width: "12rem", textAlign: "center" }}></Column>
              <Column header="Partner" body={actionMitra} exportable={false} style={{ width: "16rem" }}></Column>
            </DataTable>
          </div>
        </div>
      </main>

      <Dialog header="Availability Comparison" draggable={false} visible={visible} style={{ width: "50vw" }} onHide={closeDialog} footer={footerContent}>
        <div className="overflow-x-auto">
          <table className="table w-full">
            <thead>
              <tr>
                <th>PEMA</th>
                <th></th>
                <th>Partner</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data.map((item) => {
                return (
                  <>
                    {item.id !== "minat_kerjasama" && (
                      <tr id={item.id} className="hover">
                        <td>{item.name}</td>
                        <td>{icon(item.pemaValue, item.id)}</td>
                        <td>{item.name}</td>
                        <td>{icon(item.mitraValue, item.id)}</td>
                      </tr>
                    )}
                    {item.id === "minat_kerjasama" && (
                      <div className="mt-4 pl-3">
                        <h1 className="font-bold">{item.name}</h1>
                        <p className="mt-1">{item.mitraValue}</p>
                      </div>
                    )}
                  </>
                );
              })}
            </tbody>
          </table>
          <table className="table w-full mt-12">
            <thead>
              <tr className="text-center">
                <th>PEMA Percentage</th>
                <th>Partner Percentage</th>
              </tr>
            </thead>
            <tbody>
              <tr className="hover text-center">
                <td>{totalValues.pema}%</td>
                <td>{totalValues.mitra}%</td>
              </tr>
            </tbody>
          </table>
        </div>
      </Dialog>
    </div>
  );
};

export default ProjectDetail;
