import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Navigate } from "react-router-dom";
import Table from "../../components/datatable/DataTable";

const Project = () => {
  const accessToken = localStorage.getItem("access_token");
  const url = "https://bendmitra.ptpema.co.id/api/project";
  const columns = [
    { field: "nama_project", header: "Project Name" },
    { field: "deskripsi", header: "Description", type: "textarea" },
    { field: "nama_bidang_usaha", header: "Type of Business" },
    { field: "status", header: "Status" },
  ];

  if (localStorage.getItem("role") !== "Admin") {
    return <Navigate to="/" />;
  }

  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }
  return (
    <div className="container">
      <Sidebar />
      <main>
        <Navbar title="Project List" />
        <div className="content">
          <div className="task-list">
            <Table id={"id_project"} sendFiles={false} action={true} project={true} edit={true} columns={columns} url={url}></Table>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Project;
