const Header = (props) => {
  const handleMenu = () => {
    const sideMenu = document.querySelector("aside");
    sideMenu.style.display = "block";
  };

  return (
    <div className="header">
      <button onClick={handleMenu} className={`menu-bar sm:menu-hide`} id="menu-btn">
        <span className="material-symbols-rounded">menu</span>
      </button>
      <h1>{props.title}</h1>
    </div>
  );
};
export default Header;
