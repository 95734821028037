import { Link, Navigate } from "react-router-dom";
import axios from "axios";
import { useState } from "react";
import { Avatar } from "primereact/avatar";

const AccountBar = (props) => {
  const [redirect, setRedirect] = useState(false);
  const url = "https://bendmitra.ptpema.co.id/api/logout";

  if (redirect) {
    return <Navigate to="/login" />;
  }
  const handleLogout = async (e) => {
    let result = await axios.get(`${url}`, {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("access_token")}`,
      },
    });
    if (result) {
      localStorage.clear();
      setRedirect(true);
    }
  };
  return (
    <div className="top">
      <div className="notification">
        {/* <span className="material-symbols-rounded">notifications</span>
        <div className="counter">2</div> */}
      </div>
      <div className="dropdown dropdown-hover hover:cursor-pointer">
        <div className="user" tabIndex={0}>
          <div className="info">
            <p>
              Hey, <b>{localStorage.getItem("nameUser")}</b>
            </p>
            <small className="text-muted">{localStorage.getItem("role")}</small>
          </div>
          <div className="avatar">
            <Avatar image={localStorage.getItem("profile_picture")} size="large" shape="circle" />
          </div>
        </div>
        <ul tabIndex={0} className="dropdown-content menu p-2 shadow bg-base-100 right-0 rounded-box w-40 md:w-full">
          <li>
            <Link to={"/profile"}>Profil</Link>
          </li>
          <li>
            <Link onClick={handleLogout}>Logout</Link>
          </li>
        </ul>
      </div>
    </div>
  );
};
export default AccountBar;
