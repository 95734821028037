import React, { useRef, useState } from "react";
import { Toast } from "primereact/toast";
import { FileUpload } from "primereact/fileupload";
import { ProgressBar } from "primereact/progressbar";
import { Button } from "primereact/button";
import { Tag } from "primereact/tag";

const Documents = (props) => {
  const toast = useRef(null);
  const [totalSize, setTotalSize] = useState(0);
  const fileUploadRef = useRef(null);
  const [count, setCount] = useState(0);

  const onTemplateSelect = (e) => {
    let _totalSize = totalSize;
    let files = e.files;
    Object.keys(files).forEach((key) => {
      _totalSize += files[key].size || 0;
    });

    if (_totalSize <= 2 * 10 ** 6) {
      let _count = count + 1;
      if (props.multiple) {
        props.onFileChange(_count.toString(), files[0]);
      } else {
        props.onFileChange(props.name, files[0]);
      }
      setCount(_count);
    }
    setTotalSize(_totalSize);
  };

  const onTemplateUpload = (e) => {
    let _totalSize = 0;
    e.files.forEach((file) => {
      _totalSize += file.size || 0;
    });

    setTotalSize(_totalSize);
    toast.current.show({ severity: "info", summary: "Success", detail: "File Uploaded" });
  };

  const onTemplateRemove = (file, callback) => {
    setTotalSize(totalSize - file.size);
    props.onFileChange(null, file);
    callback();
  };

  const onTemplateClear = () => {
    setTotalSize(0);
  };

  const headerTemplate = (options) => {
    const { className, chooseButton, cancelButton } = options;
    const value = totalSize / 20000;
    const formatedValue = fileUploadRef && fileUploadRef.current ? fileUploadRef.current.formatSize(totalSize) : "0 B";

    return (
      <div className={className} style={{ backgroundColor: "transparent", display: "flex", alignItems: "center" }}>
        {totalSize <= 2 * 10 ** 6 && chooseButton}
        {cancelButton}
        <div className="flex items-center gap-3 ml-auto">
          <span>{formatedValue} / 2 MB</span>
          <ProgressBar value={value} color={value >= 87 && "red"} showValue={false} style={{ width: "10rem", height: "12px" }}></ProgressBar>
        </div>
      </div>
    );
  };

  const itemTemplate = (file, props) => {
    return (
      <>
        <div className="flex lg:justify-between">
          <div className="flex flex-wrap items-center w-8">
            <img alt={file.name} role="presentation" src={file.objectURL} width={100} />
            <span className="flex flex-col text-left w-8">
              <p className="truncate">{file.name}</p>
              <small>{new Date().toLocaleDateString()}</small>
            </span>
          </div>
          <div className="flex items-center gap-4">
            <Tag value={props.formatSize} severity="warning" className="px-3 py-2 w-24" />
            <Button type="button" icon="pi pi-times" className="p-button-outlined p-button-rounded p-button-danger p-3 " onClick={() => onTemplateRemove(file, props.onRemove)} />
          </div>
        </div>
      </>
    );
  };

  const emptyTemplate = () => {
    return (
      <div className="flex flex-col items-center">
        <i className="pi pi-images mt-3 p-5" style={{ fontSize: "5em", borderRadius: "50%", backgroundColor: "var(--surface-b)", color: "var(--surface-d)" }}></i>
        <span style={{ fontSize: "1.2em", color: "var(--text-color-secondary)" }} className="my-5">
          Drag and Drop Images Here
        </span>
      </div>
    );
  };

  return (
    <div>
      <Toast ref={toast}></Toast>

      <FileUpload
        ref={fileUploadRef}
        accept="image/*"
        multiple={props.multiple}
        name={props.name}
        maxFileSize={2000000}
        onUpload={onTemplateUpload}
        onSelect={onTemplateSelect}
        onError={onTemplateClear}
        onClear={onTemplateClear}
        headerTemplate={headerTemplate}
        itemTemplate={itemTemplate}
        emptyTemplate={emptyTemplate}
      />
    </div>
  );
};

export default Documents;
