import Logo from "../../assets/logo.png";
import { Link, Navigate } from "react-router-dom";
import { useState, useRef } from "react";
import { Toast } from "primereact/toast";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import axios from "axios";
import Swal from "sweetalert2";

const ForgotPass = () => {
  const [redirect, setRedirect] = useState(false);
  const toast = useRef(null);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validate: (data) => {
      let errors = {};
      let validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;

      if (!data.email) {
        errors.email = "Email is required.";
      }

      if (!validRegex.test(data.email)) {
        errors.email = "Email is not valid.";
      }

      return errors;
    },
    onSubmit: (data) => {
      Swal.fire({
        title: "Loading...",
        timer: 3000,
        timerProgressBar: true,
        showConfirmButton: false,
        didOpen: () => {
          Swal.showLoading();
        },
      });
      axios
        .post("https://bendmitra.ptpema.co.id/api/forgot-password", data)
        .then(() => {
          toast.current.show({ severity: "success", summary: "Success", detail: "Please check your email to reset your password!", life: 3000 });
          setTimeout(() => {
            setRedirect(true);
          }, 3000);
        })
        .catch((err) => {
          toast.current.show({ severity: "error", summary: "Error", detail: err.response.data.message, life: 5000 });
        });
    },
  });

  const isFormFieldInvalid = (name) => !!(formik.touched[name] && formik.errors[name]);

  const getFormErrorMessage = (name) => {
    return isFormFieldInvalid(name) ? <small className="p-error">{formik.errors[name]}</small> : <small className="p-error">&nbsp;</small>;
  };

  return (
    <>
      {redirect && <Navigate to="/" />}
      <Toast ref={toast} />
      <div className="login">
        <div className="login-card">
          <img src={Logo} alt="logo" />
          <div className="lc-title text-center">
            <h2 className="font-bold text-lg">Forgot Password?</h2>
            <p>You will receive an e-mail to reset your password!</p>
          </div>
          <div className="login-form flex flex-col justify-center">
            <form onSubmit={formik.handleSubmit} className="flex flex-col gap-2 mt-2">
              <span className="p-float-label">
                <InputText
                  id="email"
                  name="email"
                  style={{ width: "100%" }}
                  value={formik.values.email}
                  onChange={(e) => {
                    formik.setFieldValue("email", e.target.value);
                  }}
                  keyfilter={/^[^\s]+$/}
                  className={classNames({ "p-invalid": isFormFieldInvalid("email") })}
                />
                <label htmlFor="email">Email</label>
              </span>
              {getFormErrorMessage("email")}

              <Button type="submit" label="Send" />

              <div className="flex flex-col items-center mt-2">
                <p className="flex gap-1 mr-1">
                  Already have an account?
                  <Link to="/login" className="text-blue-500">
                    Login!
                  </Link>
                </p>
                <p className="flex gap-1">
                  Didn't have account yet?
                  <Link to="/register" className="text-blue-500">
                    Sign Up!
                  </Link>
                </p>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};
export default ForgotPass;
