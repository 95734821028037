import { Navigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import Table from "../../components/datatable/DataTable";

const Mitra = () => {
  const accessToken = localStorage.getItem("access_token");
  const url = "https://bendmitra.ptpema.co.id/api/mitra";
  if (localStorage.getItem("role") !== "Admin") {
    return <Navigate to="/" />;
  }
  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }

  const columns = [
    { field: "name", header: "Company Name" },
    { field: "email", header: "Company Email" },
    { field: "nama_bidang_usaha", header: "Type of Business" },
    { field: "alamat", header: "Address" },
    { field: "no_hp", header: "Phone Number" },
  ];

  return (
    <div className="container">
      <Sidebar />
      <main>
        <Navbar title="Partnership" />
        <div className="content">
          <div className="task-list">
            <Table id={"id_user"} sendFiles={false} action={true} detailMitra={true} edit={true} columns={columns} url={url}></Table>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Mitra;
