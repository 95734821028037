import "./sidebar.css";
import Logo from "../../assets/logo.png";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";

function SidebarMenu(props) {
  const role = localStorage.getItem("role");
  let link;
  if (role === "Admin") {
    link = props.to.split("/")[1];
  } else {
    link = props.to;
  }
  return (
    <Link to={"/" + props.to} className={`link ${props.currentPath === link && "active"}`}>
      <span className="material-symbols-rounded">{props.icon}</span>
      <h3>{props.title}</h3>
    </Link>
  );
}

function Collapse(props) {
  const [open, setOpen] = useState(true);
  useEffect(() => {
    const collapse = document.querySelector("input");
    const arr = document.querySelectorAll(".collapse-content .link");
    for (let index = 0; index < arr.length; index++) {
      if (collapse.checked === false) {
        setOpen(true);
      } else {
        setOpen("collapse-close");
      }
    }
  }, []);
  return (
    <div className={`collapse collapse-arrow`} id="profile-menu">
      <input
        type="checkbox"
        className="cursor-pointer"
        checked={open}
        onClick={() => {
          if (open === false) {
            setOpen(true);
          } else {
            setOpen(false);
          }
        }}
      />
      <div className="collapse-title">
        <span className="material-symbols-rounded">person</span>
        <h3>Company Profile</h3>
      </div>
      <div className="collapse-content">
        <SidebarMenu currentPath={props.path} to={"syarat"} icon="task" title="Company Terms" />
        <SidebarMenu currentPath={props.path} to={"direksi"} icon="supervisor_account" title="Director Form" />
        <SidebarMenu currentPath={props.path} to={"portofolio"} icon="demography" title="Portfolio" />
      </div>
    </div>
  );
}

const Sidebar = () => {
  const location = useLocation();
  const role = localStorage.getItem("role");
  let path;
  if (role === "Admin") {
    path = location.pathname.split("/")[2];
  } else {
    path = location.pathname.split("/")[1];
  }
  const handleClose = () => {
    const sideMenu = document.querySelector("aside");
    sideMenu.style.display = "none";
  };
  return (
    <>
      <aside>
        <div className="sidebar md:w-1/5 lg:w-1/5 xl:w-2 z-10">
          <div className="sidebar-top">
            <div className="logo pt-2">
              <Link to="/">
                <img src={Logo} alt="logo" />
              </Link>
            </div>
            <button className="close" onClick={handleClose}>
              <span className="material-symbols-rounded">close</span>
            </button>
          </div>
          <div className="sidebar-menu">
            <SidebarMenu currentPath={path} to={""} icon="dashboard" title="Dashboard" />
            {role === "Admin" && (
              <>
                <SidebarMenu currentPath={path} to={"admin/mitra"} icon="supervisor_account" title="Partner" />
                <SidebarMenu currentPath={path} to={"admin/projects"} icon="list_alt" title="Project List" />
              </>
            )}
            {role === "Mitra" && (
              <>
                <Collapse path={path} />
                <SidebarMenu currentPath={path} to={"pengajuan"} icon="pending_actions" title="Submission" />
                <SidebarMenu currentPath={path} to={"history"} icon="history" title="History" />
              </>
            )}
          </div>
        </div>
      </aside>
    </>
  );
};
export default Sidebar;
