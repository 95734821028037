import React, { useState, useRef, useEffect } from "react";
import { classNames } from "primereact/utils";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { Toast } from "primereact/toast";
import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import Swal from "sweetalert2";
import axios from "axios";
import Documents from "../fileupload/Documents";
import { InputTextarea } from "primereact/inputtextarea";
import { Galleria } from "primereact/galleria";
import { Checkbox } from "primereact/checkbox";
import { Image } from "primereact/image";
import { ScrollPanel } from "primereact/scrollpanel";
import { Dropdown } from "primereact/dropdown";
import { Navigate } from "react-router-dom";
import { MultiSelect } from "primereact/multiselect";
import { Calendar } from "primereact/calendar";

export default function Table(props) {
  let emptyData = {};
  const accessToken = localStorage.getItem("access_token");
  const [dialogData, setDialogData] = useState(false);
  const [dialogGalery, setDialogGalery] = useState(false);
  const [selectedDatas, setSelectedDatas] = useState(null);
  const [submitted, setSubmitted] = useState(false);
  const [globalFilter, setGlobalFilter] = useState(null);
  const [newData, setNewData] = useState(false);
  const toast = useRef(null);
  const dt = useRef(null);
  const [data, setData] = useState(emptyData);
  const [datas, setDatas] = useState();
  let files = [];
  const [images, setImages] = useState(null);
  const [ketersediaan, setKetersediaan] = useState([]);
  const [statusProject, setStatusProject] = useState(null);
  const status = [{ name: "OPEN" }, { name: "CLOSED" }];
  const [redirect, setRedirect] = useState(false);
  const [dataBidang, setDataBidang] = useState(null);
  const [selectedBidang, setSelectedBidang] = useState([]);
  const [selectedYear, setSelectedYear] = useState({});
  const [selectedPartnerStatus, setSelectedPartnerStatus] = useState({ name: "All", code: "all" });
  const [filterDatas, setFilterDatas] = useState(null);

  const [property, setProperty] = useState({
    id: null,
    linkTo: null,
  });

  const [headers, setHeaders] = useState({
    headers: {
      Authorization: `Bearer ${accessToken}`,
      "Content-Type": "multipart/form-data",
    },
  });

  const partnerStatus = [
    { name: "All", code: "all" },
    { name: "Complete", code: "complete" },
    { name: "Incomplete", code: "incomplete" },
  ];

  useEffect(() => {
    setSubmitted(false);
    axios
      .get(props.url, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => {
        if (props.sendFiles === true) {
          setHeaders({
            headers: {
              Authorization: `Bearer ${accessToken}`,
              "Content-Type": "multipart/form-data",
            },
          });
        }
        setSelectedDatas(null);
        setDatas(resp.data);
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetching Data", life: 3000 });
      });

    axios
      .get("https://bendmitra.ptpema.co.id/api/bisnis", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => {
        setDataBidang(resp.data);
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetching Data", life: 3000 });
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [submitted]);

  // DIALOG HANDLER
  const openNew = () => {
    setStatusProject(status[0]);
    setSelectedYear({});
    setKetersediaan([]);
    setData(emptyData);
    setSubmitted(false);
    setDialogData(true);
    setNewData(true);
  };

  const hideDialog = () => {
    setSelectedYear({});
    setSubmitted(false);
    setDialogData(false);
    setSelectedBidang([]);
  };

  const hideDialogGalery = () => {
    setDialogGalery(false);
  };

  // ADDITIONAL
  const exportCSV = () => {
    dt.current.exportCSV();
  };

  const filterPartnerData = (e) => {
    setSelectedPartnerStatus(e.value);
    let _data = [...datas];
    let _result = [];

    if (e.value.code === "all") {
      _result = null;
    } else if (e.value.code === "complete") {
      _result = _data.filter((data) => {
        return data.lengkap_dokumen === true;
      });
    } else {
      _result = _data.filter((data) => {
        return data.lengkap_dokumen === false;
      });
    }

    setFilterDatas(_result);
  };

  // ACTION HANDLER
  const saveData = () => {
    if (!newData) {
      if (data.nilai_po === undefined) {
        let length = Object.keys(data).length - 8;
        data.length = length;
      } else {
        let length = Object.keys(data).length - 9;
        data.length = length;
      }
      axios
        .post(`${props.url}/${data[props.id]}`, data, headers)
        .then(() => {
          toast.current.show({ severity: "success", summary: "Successful", detail: "Data Updated", life: 3000 });
        })
        .catch(() => {
          toast.current.show({ severity: "error", summary: "Error", detail: "Failed To Update Data", life: 3000 });
        });
    } else {
      if (data.nilai_po === undefined) {
        let length = Object.keys(data).length - 4;
        data.length = length;
      } else {
        let length = Object.keys(data).length - 5;
        data.length = length;
      }
      axios
        .post(`${props.url}`, data, headers)
        .then(() => {
          toast.current.show({ severity: "success", summary: "Successful", detail: "Data Created", life: 3000 });
        })
        .catch(() => {
          toast.current.show({ severity: "error", summary: "Error", detail: "Failed To Create Data", life: 3000 });
        });
      setNewData(false);
    }
    setSelectedYear({});
    setSelectedBidang([]);
    setKetersediaan([]);
    setSubmitted(true);
    setDialogData(false);
    setData(emptyData);
    setStatusProject(status[0]);
  };

  const editData = (data) => {
    axios
      .get(`${props.url}/${data[props.id]}/edit`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => {
        let _data = resp.data;

        setStatusProject({ name: resp.data.status });

        if (_data.ketersediaan) {
          let _ketersediaan = Object.entries(_data.ketersediaan);
          delete _data.ketersediaan;

          _ketersediaan = _ketersediaan.filter(([key, value]) => {
            return value !== null && value !== 0 && value !== "0" ? [key, value] : null;
          });

          _ketersediaan = Object.fromEntries(_ketersediaan);
          _data = { ..._data, ..._ketersediaan };
          setKetersediaan(Object.keys(_ketersediaan));
        }

        if (resp.data.nama_bidang_usaha) {
          let _bidang = resp.data.nama_bidang_usaha.split(",");
          delete resp.data.nama_bidang_usaha;

          let _dataBidang = [...dataBidang];
          let _intersection = _dataBidang.filter((a) => _bidang.some((b) => a.nama_bidang_usaha === b));
          setSelectedBidang(_intersection);
          _data.bidang = _intersection;
          setData(_data);
        } else {
          setData(_data);
        }

        setDialogData(true);
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetching Data", life: 3000 });
      });
  };

  // DELETE HANDLER
  const confirmDeleteData = (data) => {
    let id = data[`${props.id}`];
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`${props.url}/${id}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then((result) => {
            if (result.status === 200) {
              setSubmitted(true);
              Swal.fire("Deleted!", "Your file has been deleted.", "success");
            } else {
              Swal.fire("Error", "Your file not deleted.", "error");
            }
          });
      }
    });
  };

  const confirmDeleteSelected = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let lengthData = Object.keys(datas).length;
        if (lengthData === selectedDatas.length) {
          if (localStorage.getItem("role") === "Admin") {
            let _data = { ids: selectedDatas };
            axios
              .post(`${props.url}/delete`, _data, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
              .then(() => {
                setSubmitted(true);
                toast.current.show({ severity: "success", summary: "Successful", detail: "Data Has Been Deleted", life: 3000 });
              })
              .catch(() => {
                toast.current.show({ severity: "error", summary: "Error", detail: "Data Can't Be Deleted", life: 3000 });
              });
          } else {
            axios
              .delete(`${props.url}/${selectedDatas[0].id_user}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              })
              .then((result) => {
                if (result.status === 200) {
                  setSubmitted(true);
                  toast.current.show({ severity: "success", summary: "Successful", detail: "Data Has Been Deleted", life: 3000 });
                } else {
                  toast.current.show({ severity: "error", summary: "Error", detail: "Data Can't Be Deleted", life: 3000 });
                }
              });
          }
        } else {
          let submit = false;
          Promise.all(
            selectedDatas.map(async (data) => {
              let result = await axios.delete(`${props.url}/${data[props.id]}`, {
                headers: {
                  Authorization: `Bearer ${accessToken}`,
                },
              });
              if (result.status === 200) {
                submit = true;
              }
            })
          ).then(() => {
            if (submit !== false) {
              setSubmitted(true);
              toast.current.show({ severity: "success", summary: "Successful", detail: "Data Has Been Deleted", life: 3000 });
            } else {
              toast.current.show({ severity: "error", summary: "Error", detail: "Data Can't Be Deleted", life: 3000 });
            }
          });
        }
      }
    });
  };

  // INPUT HANDLER
  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _data = { ...data };
    _data[`${name}`] = val;
    if (name === "status") {
      setStatusProject(val);
      _data["status"] = val.name;
    } else if (props.project) {
      _data["status"] = status[0].name;
    }
    setData(_data);
  };

  const onInputNumberChange = (e, name) => {
    const val = e.value || 0;
    let _data = { ...data };
    _data[`${name}`] = val;
    setData(_data);
  };
  const onInputYearChange = (e, name) => {
    const val = e.value || 0;
    let _year = { ...selectedYear };
    let _data = { ...data };

    _year[`${name}`] = val;
    _data[`${name}`] = val.getFullYear().toString();

    setSelectedYear(_year);
    setData(_data);
  };

  const onFileChange = (name, file) => {
    if (name === null) {
      files.splice(files.indexOf(file), 1);
    } else {
      files.push(file);
      let _data = { ...data };
      _data[`${name}`] = file;
      setData(_data);
    }
  };

  const onKetersediaanChange = (e) => {
    let _ketersediaan = [...ketersediaan];
    if (e.checked) _ketersediaan.push(e.target.name);
    else _ketersediaan.splice(_ketersediaan.indexOf(e.target.name), 1);
    setKetersediaan(_ketersediaan);

    let _data = { ...data };
    // eslint-disable-next-line
    ketersediaan.map((key) => {
      if (_data[key]) {
        delete _data[`${key}`];
      }
    });

    _ketersediaan = _ketersediaan.reduce((a, v) => ({ ...a, [v]: 1 }), {});

    if (_ketersediaan.modal !== undefined) {
      _ketersediaan.modal = data.modal;
    }

    if (_ketersediaan.aset !== undefined) {
      _ketersediaan.aset = data.aset;
    }
    _data = { ..._data, ..._ketersediaan };

    setData(_data);
  };

  const onBidangChange = (e) => {
    let _data = { ...data };
    _data.bidang = [...e.value];
    setData(_data);
    setSelectedBidang(e.value.flat(1));
  };

  // TEMPLATE
  const actionBodyTemplate = (rowData) => {
    return (
      <div className="flex justify-center gap-3">
        {props.edit && <Button icon="pi pi-pencil" rounded outlined className="mr-2" onClick={() => editData(rowData)} />}
        <Button icon="pi pi-trash" rounded outlined severity="danger" onClick={() => confirmDeleteData(rowData)} />
      </div>
    );
  };

  const DialogFooter = (
    <React.Fragment>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={hideDialog} />
      <Button label="Save" icon="pi pi-check" onClick={saveData} />
    </React.Fragment>
  );

  const header = (
    <div className="flex header-datatable justify-between">
      <div className="flex action-table justify-start gap-3">
        <Button label="New" icon="pi pi-plus" severity="success" size="small" disabled={props.detailMitra} onClick={openNew} />
        <Button label="Delete" icon="pi pi-trash" severity="danger" size="small" onClick={confirmDeleteSelected} disabled={!selectedDatas || !selectedDatas.length} />
        <Button label="Export" icon="pi pi-upload" className="p-button-help" size="small" onClick={exportCSV} />
      </div>

      {props.detailMitra && (
        <div className="flex items-center gap-4">
          <h3 className="font-bold">Status Partner's Document</h3>
          <Dropdown value={selectedPartnerStatus} onChange={(e) => filterPartnerData(e)} options={partnerStatus} optionLabel="name" placeholder="Select Partner Status" className="w-full md:w-14rem" />
        </div>
      )}

      <div>
        <span className="search p-input-icon-left">
          <i className="pi pi-search" />
          <InputText type="search" onInput={(e) => setGlobalFilter(e.target.value)} placeholder="Search..." />
        </span>
      </div>
    </div>
  );

  const itemTemplate = (item) => {
    return <Image src={item.itemImageSrc} alt={item.alt} preview />;
  };

  const actionGaleryBodyTemplate = (rowData) => {
    return (
      <div className="flex justify-center">
        <Button label="Galeri" className="h-12" severity="info" size="small" onClick={() => toGalery(rowData)} />
      </div>
    );
  };

  const actionProjectTemplate = (rowData) => {
    return (
      <div className="flex justify-center">
        <Button label="See Partnership" className="h-12" severity="info" size="small" onClick={() => listMitra(rowData)} />
      </div>
    );
  };

  const actionMitraTemplate = (rowData) => {
    return (
      <div className="flex justify-center">
        <Button label="Detail" className="h-12" severity="info" size="small" onClick={() => detailMitra(rowData)} />
      </div>
    );
  };

  const DialogFooterGalery = (
    <React.Fragment>
      <Button label="Oke" onClick={hideDialogGalery} />
    </React.Fragment>
  );

  // Galeri data
  const toGalery = async (data) => {
    let response = await axios.get(`${props.url}/galeri/${data.id_portofolio}`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    });
    if (response.data.length > 0) {
      setImages(response.data);
    } else {
      setImages(null);
    }
    setDialogGalery(true);
  };

  const listMitra = (data) => {
    setProperty({ id: data.id_project, linkTo: "projects" });
    setRedirect(true);
  };

  const detailMitra = (data) => {
    setProperty({ id: data.id_user, linkTo: "mitra" });
    setRedirect(true);
  };

  return (
    <>
      {redirect && <Navigate to={`/admin/${property.linkTo}/detail/${property.id}`} />}
      <div>
        <Toast ref={toast} />
        <div className="card">
          <DataTable
            ref={dt}
            value={filterDatas ? filterDatas : datas}
            selection={selectedDatas}
            onSelectionChange={(e) => setSelectedDatas(e.value)}
            dataKey={props.id}
            paginator
            removableSort
            rows={10}
            rowsPerPageOptions={[5, 10, 25]}
            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} datas"
            globalFilter={globalFilter}
            header={header}
          >
            <Column selectionMode="multiple" exportable={false}></Column>
            {props.columns.map((col) => col.field !== "galery" && <Column key={col.field} field={col.field} header={col.header} sortable={col.field !== "deskripsi"} style={{ minWidth: "4rem" }} />)}
            {props.sendFiles === true && <Column header={props.columns[5].header} body={actionGaleryBodyTemplate} exportable={false} style={{ minWidth: "4rem" }}></Column>}
            {props.project === true && <Column header="Partnership" body={actionProjectTemplate} exportable={false} style={{ minWidth: "4rem" }}></Column>}
            {props.detailMitra && <Column header="Detail" body={actionMitraTemplate} exportable={false} style={{ minWidth: "4rem" }}></Column>}
            {props.action === true && <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: "4rem" }}></Column>}
          </DataTable>
        </div>

        {/* BEGIN Add data */}
        <Dialog visible={dialogData} style={{ width: "48rem" }} breakpoints={{ "960px": "75vw", "641px": "90vw" }} header="Details Data" modal className="p-fluid" footer={DialogFooter} draggable={false} onHide={hideDialog}>
          <ScrollPanel style={{ width: "100%", height: "32rem" }} className="custombar">
            {props.columns.map((col) => {
              return (
                <>
                  <div className="field px-2">
                    <label htmlFor={col.field} className="font-bold">
                      {col.header}
                    </label>
                    {col.type === "integer" && col.field !== "galery" && col.field !== "nilai_po" && (
                      <InputNumber useGrouping={false} id={col.field} value={data[col.field]} onChange={(e) => onInputNumberChange(e, `${col.field}`)} required className={classNames({ "p-invalid": submitted && !data[col.field] })} />
                    )}
                    {col.type === "year" && <Calendar value={selectedYear[col.field]} onChange={(e) => onInputYearChange(e, `${col.field}`)} view="year" dateFormat="yy" />}
                    {col.field === "nilai_po" && (
                      <InputNumber
                        id={col.field}
                        value={data[col.field]}
                        onChange={(e) => onInputNumberChange(e, `${col.field}`)}
                        mode="currency"
                        currency="IDR"
                        locale="id-ID"
                        className={classNames({ "p-invalid": submitted && !data[col.field] })}
                      />
                    )}
                    {col.type !== "integer" && col.type !== "textarea" && col.field !== "galery" && col.field !== "status" && col.field !== "nama_bidang_usaha" && col.type !== "year" && (
                      <InputText id={col.field} value={data[col.field]} onChange={(e) => onInputChange(e, `${col.field}`)} required readOnly={col.field === "email"} className={classNames({ "p-invalid": submitted && !data[col.field] })} />
                    )}
                    {col.type === "textarea" && col.field !== "galery" && (
                      <InputTextarea autoResize id={col.field} value={data[col.field]} onChange={(e) => onInputChange(e, `${col.field}`)} required className={classNames({ "p-invalid": submitted && !data[col.field] })} />
                    )}
                    {col.field === "nama_bidang_usaha" && (
                      <MultiSelect value={selectedBidang} onChange={(e) => onBidangChange(e)} options={dataBidang} optionLabel={`${col.field}`} maxSelectedLabels={3} placeholder="Select Type of Business" />
                    )}
                    {col.field === "status" && <Dropdown value={statusProject} onChange={(e) => onInputChange(e, `${col.field}`)} options={status} optionLabel="name" />}
                    {col.field === "galery" && <Documents name="galery" multiple={true} onFileChange={(name, files) => onFileChange(name, files)} listFiles={files} />}
                    {submitted && !data[col.field] && <small className="p-error">{col.header} is required.</small>}
                  </div>
                </>
              );
            })}
            {props.project && (
              <>
                <div className="text-base ms-2 font-bold mb-2">
                  <p>Owned Availability</p>
                </div>
                <div className="flex flex-wrap flex-col gap-2 ms-2">
                  <div className="flex gap-2">
                    <Checkbox name="sdm" value="Human Resources" onChange={onKetersediaanChange} checked={ketersediaan.includes("sdm")}></Checkbox>
                    <label htmlFor="sdm">Human Resources</label>
                  </div>
                  <div className="flex gap-2">
                    <Checkbox name="teknologi" value="Technology" onChange={onKetersediaanChange} checked={ketersediaan.includes("teknologi")}></Checkbox>
                    <label htmlFor="teknologi">Technology</label>
                  </div>
                  <div className="flex gap-2">
                    <Checkbox name="modal" value="Capital" onChange={onKetersediaanChange} checked={ketersediaan.includes("modal")}></Checkbox>
                    <label htmlFor="modal">Capital</label>
                  </div>
                  {ketersediaan.includes("modal") && (
                    <div className="my-1 me-2">
                      <InputNumber placeholder="Capital" value={data.modal} mode="currency" currency="IDR" locale="id-ID" onChange={(e) => onInputNumberChange(e, "modal")} />
                    </div>
                  )}
                  <div className="flex gap-2">
                    <Checkbox name="aset" value="Asset" onChange={onKetersediaanChange} checked={ketersediaan.includes("aset")}></Checkbox>
                    <label htmlFor="aset">Asset</label>
                  </div>
                  {ketersediaan.includes("aset") && (
                    <div className="my-1 me-2">
                      <InputText placeholder="Asset" value={data.aset} onChange={(e) => onInputChange(e, "aset")} />
                    </div>
                  )}
                  <div className="flex gap-2">
                    <Checkbox name="networking" value="Networking" onChange={onKetersediaanChange} checked={ketersediaan.includes("networking")}></Checkbox>
                    <label htmlFor="networking">Networking</label>
                  </div>
                </div>
              </>
            )}
          </ScrollPanel>
        </Dialog>
        {/* END Add Data*/}

        {/* BEGIN Show Galery */}
        <Dialog visible={dialogGalery} style={{ width: "32rem" }} breakpoints={{ "960px": "75vw", "641px": "90vw" }} header="Galery" modal className="p-fluid" footer={DialogFooterGalery} draggable={false} onHide={hideDialogGalery}>
          <div className="card">
            {images === null && <p>Data not Found!</p>}
            <Galleria value={images} style={{ maxWidth: "640px" }} showThumbnails={false} showIndicators item={itemTemplate} />
          </div>
        </Dialog>
        {/* END Show Galery*/}
      </div>
    </>
  );
}
