import Sidebar from "../../components/sidebar/Sidebar";
import { useEffect, useRef, useState } from "react";
import UploadFile from "../../components/fileupload/UploadFile";
import DownloadFile from "../../components/filedownload/DownloadFile";
import axios from "axios";
import { Toast } from "primereact/toast";
import { Navigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import { Button } from "primereact/button";
import Swal from "sweetalert2";

const Syarat = () => {
  const accessToken = localStorage.getItem("access_token");
  const toast = useRef(null);
  const data = new FormData();
  const [files, setFiles] = useState(null);
  const [fileDate, setFileDate] = useState(null);
  const [submit, setSubmit] = useState(false);

  useEffect(() => {
    setSubmit(false);
    axios
      .get("https://bendmitra.ptpema.co.id/api/syarat", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((resp) => {
        setFiles(resp.data);
        if (resp.data !== "") {
          setFileDate(resp.data.created_at.slice(0, 10).split("-").reverse().join("/"));
        }
      })
      .catch((err) => {
        toast.current.show({ severity: "error", summary: "Failed", detail: "Failed While Fetch Data", life: 3000 });
      });
    // eslint-disable-next-line
  }, [submit]);

  if (localStorage.getItem("role") !== "Mitra" && accessToken) {
    return <Navigate to="/admin" />;
  }

  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }

  const onFileChange = (name, files) => {
    if (files === null) {
      data.delete(name);
    } else {
      data.append(name, files);
    }
  };

  // submit handler
  const submitHandler = (e) => {
    e.preventDefault();

    axios
      .post("https://bendmitra.ptpema.co.id/api/syarat", data, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          "Content-Type": "multipart/form-data",
        },
      })
      .then(() => {
        setSubmit(true);
        toast.current.show({ severity: "success", summary: "Success", detail: "File Uploaded Successfully", life: 3000 });
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Failed", detail: "Failed To Upload File", life: 3000 });
      });
  };

  const deleteAllHandler = (files) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
    }).then((result) => {
      if (result.isConfirmed) {
        axios
          .delete(`https://bendmitra.ptpema.co.id/api/syarat/${files.id_user}`, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          })
          .then(() => {
            setSubmit(true);
            toast.current.show({ severity: "success", summary: "Success", detail: "Successfully Deleted All Files", life: 3000 });
          })
          .catch(() => {
            toast.current.show({ severity: "error", summary: "Failed", detail: "Failed To Delete All Files", life: 3000 });
          });
      }
    });
  };

  const fileExistTemplate = () => {
    return (
      <>
        <h3 className="font-bold">
          Company Profile<span className="text-red-600">*</span>
        </h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"company_profile"}></DownloadFile>
        <div className="border"></div>
        <h3 className="font-bold">
          Work Report for the Last 2 Years<span className="text-red-600">*</span>
        </h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"laporan_kerja"}></DownloadFile>
        <div className="border"></div>
        <h3 className="font-bold">
          Deed of Company<span className="text-red-600">*</span>
        </h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"akta_perusahaan"}></DownloadFile>
        <div className="border"></div>
        <h3 className="font-bold">
          Type of Business (NIB)<span className="text-red-600">*</span>
        </h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"bidang_usaha"}></DownloadFile>
        <div className="form-btn flex justify-end">
          <Button label="Delete All" onClick={() => deleteAllHandler(files)} severity="danger" />
        </div>
      </>
    );
  };

  const fileNotExistTemplate = () => {
    return (
      <>
        <h3 className="font-bold">
          Company Profile<span className="text-red-600">*</span>
        </h3>
        <UploadFile name="company_profile" onFileChange={(name, files) => onFileChange(name, files)}></UploadFile>

        <h3 className="font-bold">
          Work Report for the Last 2 Years<span className="text-red-600">*</span>
        </h3>
        <UploadFile name="laporan_kerja" onFileChange={(name, files) => onFileChange(name, files)}></UploadFile>
        <h3 className="font-bold">
          Deed of Company<span className="text-red-600">*</span>
        </h3>

        <UploadFile name="akta_perusahaan" onFileChange={(name, files) => onFileChange(name, files)}></UploadFile>
        <h3 className="font-bold">
          Type of Business (NIB)<span className="text-red-600">*</span>
        </h3>

        <UploadFile name="bidang_usaha" onFileChange={(name, files) => onFileChange(name, files)}></UploadFile>
        <div className="form-btn flex justify-end">
          <Button type="submit" label="Save" />
        </div>
      </>
    );
  };

  return (
    <>
      <div className="container">
        <Sidebar />
        <main>
          <Toast ref={toast} />
          <Navbar title="Company Terms" />
          <div className="content">
            <div className="content-form">
              {!files && (
                <>
                  <form onSubmit={submitHandler} encType="multipart/form-data">
                    <div className="form-container">{fileNotExistTemplate()}</div>
                  </form>
                </>
              )}
              {files && <div className="form-container">{fileExistTemplate()}</div>}
            </div>
          </div>
        </main>
      </div>
    </>
  );
};

export default Syarat;
