import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
import "./tailwind.css";
import "primereact/resources/primereact.min.css";
import 'primeicons/primeicons.css';
import "primereact/resources/themes/lara-light-indigo/theme.css";

const root = ReactDOM.createRoot(document.querySelector("#root"));

root.render(<App />);
