import axios from "axios";
import { Button } from "primereact/button";
import Swal from "sweetalert2";

const EmailVerification = () => {
  const email = localStorage.getItem("email");
  const resend = () => {
    Swal.fire({
      title: "Loading...",
      timer: 2000,
      timerProgressBar: true,
      showConfirmButton: false,
      didOpen: () => {
        Swal.showLoading();
      },
    });
    axios
      .post("https://bendmitra.ptpema.co.id/api/email/verification-notification", null, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("cache-t")}`,
        },
      })
      .then(() => {
        Swal.fire({
          title: "Email has been sent!",
          icon: "success",
          timer: 2000,
          showConfirmButton: false,
        });
      });
  };

  return (
    <>
      <div className="h-screen login">
        <div className="flex flex-col login-card items-center gap-5 w-fit">
          <span className="material-symbols-rounded text-7xl">forward_to_inbox</span>
          <h1 className="font-bold text-6xl">Verify your email</h1>
          <div className="text-lg">
            <p>
              Almost there! We've sent a verification email to <b>{email}</b>
            </p>
            <p>You need to verify your email address to log into PEMA application.</p>
          </div>
          <Button label="Resend email" rounded onClick={resend}></Button>
        </div>
      </div>
    </>
  );
};

export default EmailVerification;
