import Logo from "../../assets/logo.png";
import Form from "../../components/form/RegisterForm";

const Register = () => {
  return (
    <>
      <div className="login overflow-auto">
        <div className="login-card mt-36 mb-3">
          <img src={Logo} alt="logo" />
          <div className="text-center">
            <h2>Welcome!</h2>
            <p>Please enter your credentials!</p>
          </div>

          <div className="w-96">
            <Form />
          </div>
        </div>
      </div>
    </>
  );
};
export default Register;
