import { useParams, Navigate } from "react-router-dom";
import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import DownloadFile from "../../components/filedownload/DownloadFile";
import { useEffect, useState } from "react";
import { InputText } from "primereact/inputtext";
import { Accordion, AccordionTab } from "primereact/accordion";
import { DataTable } from "primereact/datatable";
import { Galleria } from "primereact/galleria";
import { Image } from "primereact/image";
import { Column } from "primereact/column";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import axios from "axios";

const DetailMitra = () => {
  const params = useParams();
  const [mitra, setMitra] = useState({});
  const [directors, setDirectors] = useState([]);
  const [files, setFiles] = useState(null);
  const [fileDate, setFileDate] = useState(null);
  const [portofolio, setPortofolio] = useState([]);
  const [images, setImages] = useState(null);
  const [dialogGalery, setDialogGalery] = useState(false);
  const [photoProfile, setPhotoProfile] = useState(null);

  const directorsColumns = [
    { field: "nama", header: "Name" },
    { field: "posisi", header: "Position" },
    { field: "no_hp", header: "Phone Number" },
  ];

  const portofolioColumns = [
    { field: "nama_proyek", header: "Project Name" },
    { field: "tahun_mulai", header: "Start Year", type: "integer" },
    { field: "tahun_selesai", header: "Year Finished", type: "integer" },
    { field: "owner", header: "Owner" },
    { field: "nilai_po", header: "PO Score" },
    { field: "galery", header: "Gallery" },
  ];

  useEffect(() => {
    axios
      .get(`https://bendmitra.ptpema.co.id/api/mitra/${params.idMitra}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((resp) => {
        setMitra(resp.data.mitra);
        setDirectors(resp.data.direktur);
        setPortofolio(resp.data.portofolio);
        setPhotoProfile(resp.data.profile_picture);
        if (resp.data.syarat !== null) {
          setFiles(resp.data.syarat);
          setFileDate(resp.data.syarat.created_at.slice(0, 10).split("-").reverse().join("/"));
        }
      });
    // eslint-disable-next-line
  }, []);

  const syaratTemplate = () => {
    return (
      <>
        <h3 className="font-bold">Company Profile</h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"company_profile"}></DownloadFile>
        <div className="border"></div>
        <h3 className="font-bold">Work Report for the Last 2 Years</h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"laporan_kerja"}></DownloadFile>
        <div className="border"></div>
        <h3 className="font-bold">Deed of Company</h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"akta_perusahaan"}></DownloadFile>
        <div className="border"></div>
        <h3 className="font-bold">Type of Business (KBLI)</h3>
        <DownloadFile data={files} fileDate={fileDate} fileName={"bidang_usaha"}></DownloadFile>
      </>
    );
  };

  const toGalery = (data) => {
    axios
      .get(`https://bendmitra.ptpema.co.id/api/portofolio/galeri/${data.id_portofolio}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((resp) => {
        if (resp.data.length > 0) {
          setImages(resp.data);
        } else {
          setImages(null);
        }
      });
    setDialogGalery(true);
  };

  const actionGaleryBodyTemplate = (rowData) => {
    return (
      <div className="flex justify-center">
        <Button label="Galeri" className="h-12" severity="info" size="small" onClick={() => toGalery(rowData)} />
      </div>
    );
  };

  const itemTemplate = (item) => {
    return <Image src={item.itemImageSrc} alt={item.alt} preview />;
  };

  const DialogFooterGalery = () => {
    return (
      <>
        <Button label="Oke" onClick={() => setDialogGalery(false)} />
      </>
    );
  };

  if (localStorage.getItem("role") !== "Admin") {
    return <Navigate to="/" />;
  }

  return (
    <>
      <div className="container">
        <Sidebar />
        <main>
          <Navbar />
          <div className="content">
            <div className="content-form">
              <div className="flex justify-center mb-4">
                <img src={photoProfile} alt="profile" className="w-48 h-48 rounded-full" />
              </div>
              <Accordion multiple activeIndex={[0]}>
                <AccordionTab header="Partner Profile">
                  <div className="form-container">
                    <div className="form-item">
                      <label htmlFor="name">Company Name</label>
                      <InputText id="name" name="name" style={{ width: "100%" }} defaultValue={mitra.name} readOnly={true} />
                    </div>
                    <div className="form-item">
                      <label htmlFor="email">Company Email</label>
                      <InputText id="email" name="email" style={{ width: "100%" }} defaultValue={mitra.email} readOnly={true} />
                    </div>
                    <div className="form-item">
                      <label htmlFor="no_hp">Phone Number</label>
                      <InputText id="no_hp" name="no_hp" style={{ width: "100%" }} defaultValue={mitra.no_hp} readOnly={true} />
                    </div>
                    <div className="form-item">
                      <label htmlFor="alamat">Address</label>
                      <InputText id="alamat" name="alamat" style={{ width: "100%" }} defaultValue={mitra.alamat} readOnly={true} />
                    </div>
                  </div>
                </AccordionTab>
                <AccordionTab header="Partner's Director">
                  <div className="form-container">
                    <DataTable value={directors} dataKey="id_director" removableSort>
                      {directorsColumns.map((col) => (
                        <Column key={col.field} field={col.field} sortable exportable={false} header={col.header} />
                      ))}
                    </DataTable>
                  </div>
                </AccordionTab>
                <AccordionTab header="Company Terms">
                  <div className="form-container">
                    {!files && <p>This Partner Has Not Uploaded Company Terms</p>}
                    {files && syaratTemplate()}
                  </div>
                </AccordionTab>
                <AccordionTab header="Portfolio">
                  <div className="form-container">
                    <DataTable value={portofolio} dataKey="id_portofolio" removableSort>
                      {portofolioColumns.map((col) => col.field !== "galery" && <Column key={col.field} field={col.field} sortable exportable={false} header={col.header} />)}
                      <Column header={"Gallery"} body={actionGaleryBodyTemplate} exportable={false} style={{ minWidth: "4rem" }}></Column>
                    </DataTable>
                  </div>
                </AccordionTab>
              </Accordion>
            </div>
          </div>
          <Dialog
            visible={dialogGalery}
            style={{ width: "32rem" }}
            breakpoints={{ "960px": "75vw", "641px": "90vw" }}
            header="Galery"
            modal
            className="p-fluid"
            footer={DialogFooterGalery}
            draggable={false}
            onHide={() => setDialogGalery(false)}
          >
            <div className="card">
              {images === null && <p>This Partner Has Not Uploaded A Portfolio Gallery Yet!</p>}
              <Galleria value={images} style={{ maxWidth: "640px" }} showThumbnails={false} showIndicators item={itemTemplate} />
            </div>
          </Dialog>
        </main>
      </div>
    </>
  );
};

export default DetailMitra;
