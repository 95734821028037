import { BrowserRouter, Routes, Route } from "react-router-dom";

// Authentication
import Login from "./pages/authentication/Login";
import Register from "./pages/authentication/Register";
import ForgotPassword from "./pages/authentication/ForgotPassword";
import EmailVerification from "./pages/authentication/EmailVerification";
import ResetPassword from "./pages/authentication/ResetPassword";

// admin
import Mitra from "./pages/admin/Mitra";
import ProjectDetail from "./pages/admin/ProjectDetail";
import AdminDashboard from "./pages/admin/AdminDashboard";
import Project from "./pages/admin/Project";
import DetailMitra from "./pages/admin/DetailMitra";

// mitra
import Dashboard from "./pages/mitra/Dashboard";
import Syarat from "./pages/mitra/Syarat";
import Direksi from "./pages/mitra/Direksi";
import Portofolio from "./pages/mitra/Portofolio";
import Profile from "./pages/mitra/Profile";
import Pengajuan from "./pages/mitra/Pengajuan";
import History from "./pages/mitra/History";

const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        {/* Authentication */}
        <Route path="/login" element={<Login />}></Route>
        <Route path="/register" element={<Register />}></Route>
        <Route path="/verify" element={<EmailVerification />}></Route>
        <Route path="/forgot-password" element={<ForgotPassword />}></Route>
        <Route path="/reset-password/:token" element={<ResetPassword />}></Route>

        {/* admin */}
        <Route path="/admin" element={<AdminDashboard />}></Route>
        <Route path="/admin/mitra" element={<Mitra />}></Route>
        <Route path="/admin/mitra/detail/:idMitra" element={<DetailMitra />}></Route>
        <Route path="/admin/projects" element={<Project />}></Route>
        <Route path="/admin/projects/detail/:idProject" element={<ProjectDetail />}></Route>

        {/* mitra */}
        <Route path="/" element={<Dashboard />}></Route>
        <Route path="/syarat" element={<Syarat />}></Route>
        <Route path="/direksi" element={<Direksi />}></Route>
        <Route path="/portofolio" element={<Portofolio />}></Route>
        <Route path="/profile" element={<Profile />}></Route>
        <Route path="/pengajuan" element={<Pengajuan />}></Route>
        <Route path="/history" element={<History />}></Route>

      </Routes>
    </BrowserRouter>
  );
};
export default App;
