import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { DataView } from "primereact/dataview";
import { Dialog } from "primereact/dialog";
import { Tag } from "primereact/tag";
import "primeflex/primeflex.css";
import axios from "axios";
import { Checkbox } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import { Toast } from "primereact/toast";
import { ScrollPanel } from "primereact/scrollpanel";

export default function ViewData(props) {
  const [projects, setProjects] = useState([]);
  const [project, setProject] = useState({});
  const [visible, setVisible] = useState(false);
  const [ketersediaan, setKetersediaan] = useState([]);
  const [data, setData] = useState({});
  const [availability, setAvailability] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const toast = useRef(null);
  const isAdmin = localStorage.getItem("role") === "Admin";
  const dataPenilaian = [
    { name: "Human Resources", id: "sdm", pemaValue: null, mitraValue: null },
    { name: "Technology", id: "teknologi", pemaValue: null, mitraValue: null },
    { name: "Capital", id: "modal", pemaValue: null, mitraValue: null },
    { name: "Asset", id: "aset", pemaValue: null, mitraValue: null },
    { name: "Networking", id: "networking", pemaValue: null, mitraValue: null },
    { name: "Collaboration Interests", id: "minat_kerjasama", pemaValue: null, mitraValue: null },
  ];

  useEffect(() => {
    setRefresh(false);
    axios
      .get(`${props.urlProject}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((resp) => {
        let _data;
        if (props.dashboard) {
          setProjects(resp.data);
        } else {
          if (!props.pengajuan) {
            _data = resp.data.filter((item) => item.status_pengajuan === "ACCEPTED" || item.status_pengajuan === "REJECTED");
          } else {
            _data = resp.data.filter((item) => item.status_pengajuan === "PENDING");
          }
          setProjects(_data);
        }
      });
    // eslint-disable-next-line
  }, [refresh]);

  const getSeverity = (project) => {
    if (props.history) {
      if (project.status_pengajuan === "ACCEPTED") {
        return "success";
      } else if (project.status_pengajuan === "REJECTED") {
        return "danger";
      }
    } else {
      if (project.status === "OPEN") {
        return "success";
      } else if (project.status === "CLOSED") {
        return "danger";
      }
    }

    return "warning";
  };

  const onInputChange = (e, name) => {
    const val = (e.target && e.target.value) || "";
    let _data = { ...data };
    _data[`${name}`] = val;
    setData(_data);
  };

  const cancel = () => {
    setVisible(false);
    setKetersediaan([]);
    setData({});
  };

  const ajukan = () => {
    axios
      .post(`${props.urlKerjasama}`, data, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then(() => {
        toast.current.show({ severity: "success", summary: "Success", detail: "Successfully applied for cooperation" });
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Gagal", detail: "Failed to applied cooperation" });
      });
    setRefresh(true);
    setVisible(false);
    setData({});
    setKetersediaan([]);
  };

  const detailData = (rowData) => {
    let url;
    let _dataPenilaian = [...dataPenilaian];
    if (props.pengajuan && !isAdmin) {
      url = `${props.urlProject}/user/${rowData.id_project}`;
    }
    url = `${props.urlProject}/${rowData.id_project}`;

    if (props.history) {
      axios
        .get(`${props.urlKerjasama}/user/${rowData.id_project}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("access_token")}`,
          },
        })
        .then((resp) => {
          if (resp.data.length > 0) {
            _dataPenilaian.map((item) => (item.pemaValue = resp.data[0][item.id] !== "0" && resp.data[0][item.id] !== 0 ? resp.data[0][item.id] : "-"));
          }
        })
        .catch(() => {
          toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetch Data", life: 3000 });
        });
    }

    axios
      .get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("access_token")}`,
        },
      })
      .then((resp) => {
        _dataPenilaian.map((item) => (item.mitraValue = resp.data[0][item.id] !== "0" && resp.data[0][item.id] !== 0 ? resp.data[0][item.id] : "-"));
        setAvailability(_dataPenilaian);
      })
      .catch(() => {
        toast.current.show({ severity: "error", summary: "Error", detail: "Failed While Fetch Data", life: 3000 });
      });

    setVisible(true);
    setProject(rowData);
  };

  const close = () => {
    setAvailability([]);
    setKetersediaan([]);
    setVisible(false);
  };

  const onKetersediaanChange = (e) => {
    let _ketersediaan = [...ketersediaan];
    if (e.checked) _ketersediaan.push(e.target.name);
    else _ketersediaan.splice(_ketersediaan.indexOf(e.target.name), 1);
    setKetersediaan(_ketersediaan);

    let _data = { ...data };
    _ketersediaan = _ketersediaan.reduce((a, v) => ({ ...a, [v]: 1 }), {});
    if (_ketersediaan.modal !== undefined || _ketersediaan.aset !== undefined) {
      _ketersediaan.modal = _data.modal;
    }

    if (_ketersediaan.aset !== undefined) {
      _ketersediaan.aset = _data.aset;
    }

    _data = { id_project: project.id_project, ..._data, ..._ketersediaan };
    setData(_data);
  };

  const footerContent = (
    <div>
      <Button label="Cancel" icon="pi pi-times" outlined onClick={cancel} />
      <Button label="Apply" icon="pi pi-check" onClick={ajukan} disabled={project.status === "CLOSED"} />
    </div>
  );

  const icon = (item, id) => {
    if (id === "modal" || id === "aset") {
      return item;
    } else if (id === "minat_kerjasama") {
      return item === null || item === "" ? "-" : item;
    }

    if (item !== "-") {
      return <i className="pi pi-check"></i>;
    }

    return <i className="pi pi-times"></i>;
  };

  const itemTemplate = (project) => {
    return (
      <div className="flex flex-row content-center w-12 items-center p-4 gap-4">
        <img className="w-2 drop-shadow block mx-auto rounded" src="https://ptpema.co.id/uploads/settings/16347907124310.PNG" alt={project.nama_project} />
        <div className="flex flex-row justify-between xl:items-center items-start flex-1">
          <div className="flex flex-col items-center sm:items-start gap-2">
            <div className="text-2xl font-bold">{project.nama_project}</div>
            <div>{props.history ? <Tag value={project.status_pengajuan} severity={getSeverity(project)}></Tag> : <Tag value={project.status} severity={getSeverity(project)}></Tag>}</div>

            <div className="flex items-center gap-3 max-w-4xl">
              <p className="truncate">{project.deskripsi}</p>
            </div>
          </div>
          <div className="flex sm:flex-col sm:items-end gap-3 sm:gap-2">
            <Button label="Detail" onClick={() => detailData(project)}></Button>
          </div>
        </div>
      </div>
    );
  };

  const historyContent = () => {
    return (
      <>
        <div className="mt-5">
          {props.history && project.status_pengajuan === "ACCEPTED" && <p className="mt-4 font-medium">Congratulations, your application has been accepted, please contact PT PEMA!</p>}
          {props.history && project.status_pengajuan === "PENDING" && <p className="mt-4 font-medium">Your application is being processed, please wait!</p>}
          {props.history && project.status_pengajuan === "REJECTED" && <p className="mt-4 font-medium">Sorry, your application was rejected!</p>}
        </div>
      </>
    );
  };

  const ketersediaanContent = () => {
    return (
      <>
        <div className="text-base font-bold mt-4">
          <p>Owned Availability</p>
        </div>
        <div className="flex flex-wrap flex-col my-2 gap-2">
          <div className="flex gap-2">
            <Checkbox name="sdm" value="Human Resources" disabled={project.status === "CLOSED"} onChange={onKetersediaanChange} checked={ketersediaan.includes("sdm")}></Checkbox>
            <label htmlFor="sdm">Human Resources</label>
          </div>
          <div className="flex gap-2">
            <Checkbox name="teknologi" value="Technology" disabled={project.status === "CLOSED"} onChange={onKetersediaanChange} checked={ketersediaan.includes("teknologi")}></Checkbox>
            <label htmlFor="teknologi">Technology</label>
          </div>
          <div className="flex gap-2">
            <Checkbox name="modal" value="Capital" disabled={project.status === "CLOSED"} onChange={onKetersediaanChange} checked={ketersediaan.includes("modal")}></Checkbox>
            <label htmlFor="modal">Capital</label>
          </div>
          {ketersediaan.includes("modal") && (
            <div className="my-1 w-6">
              <InputText placeholder="Capital" onChange={(e) => onInputChange(e, "modal")} />
            </div>
          )}
          <div className="flex gap-2">
            <Checkbox name="aset" value="Asset" disabled={project.status === "CLOSED"} onChange={onKetersediaanChange} checked={ketersediaan.includes("aset")}></Checkbox>
            <label htmlFor="aset">Asset</label>
          </div>
          {ketersediaan.includes("aset") && (
            <div className="my-1 w-6">
              <InputText placeholder="Asset" disabled={project.status === "CLOSED"} onChange={(e) => onInputChange(e, "aset")} />
            </div>
          )}
          <div className="flex gap-2">
            <Checkbox name="networking" disabled={project.status === "CLOSED"} value="Networking" onChange={onKetersediaanChange} checked={ketersediaan.includes("networking")}></Checkbox>
            <label htmlFor="networking">Networking</label>
          </div>
          <div className="mt-4">
            <span className="p-float-label">
              <InputTextarea autoResize disabled={project.status === "CLOSED"} className="w-full" id="kerjasama" onChange={(e) => onInputChange(e, "minat_kerjasama")} />
              <label htmlFor="kerjasama">Collaboration Interests</label>
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <div className="card">
      <Toast ref={toast} />

      {projects.length <= 0 && (
        <>
          <h1 className="mx-auto py-72">No Project</h1>
        </>
      )}
      {projects.length > 0 && (
        <>
          <DataView value={projects} itemTemplate={itemTemplate} paginator rows={10} />
        </>
      )}

      <Dialog header="Project Details" visible={visible} draggable={false} onHide={close} footer={!isAdmin && !props.history && footerContent} style={{ width: "50vw" }} breakpoints={{ "960px": "75vw", "641px": "100vw" }}>
        <ScrollPanel style={{ width: "100%", height: "24rem" }} className="custombar">
          <div className="flex gap-2 items-center mb-2">
            <div className="text-2xl font-bold">{project.nama_project}</div>
            {props.history ? <Tag value={project.status_pengajuan} severity={getSeverity(project)}></Tag> : <Tag value={project.status} severity={getSeverity(project)}></Tag>}
          </div>
          <p>{project.deskripsi}</p>
          <>
            {props.dashboard && <h1 className="mt-4 font-bold">Availability from PEMA</h1>}
            {props.pengajuan && <h1 className="mt-4 font-bold">Availability from You</h1>}
            {!props.history && (
              <>
                <div className="overflow-x-auto">
                  <table className="table w-full">
                    <thead>
                      <tr>
                        {props.dashboard && <th className="text-center">PEMA</th>}
                        {props.pengajuan && <th className="text-center">You</th>}
                        {props.history && <th>PEMA</th>}
                        <th></th>
                        {props.history && (
                          <>
                            <th>PARTNER</th>
                            <th></th>
                          </>
                        )}
                      </tr>
                    </thead>
                    <tbody>
                      {availability.map((item) => {
                        return (
                          <tr id={item.id} className="hover">
                            <td>{item.name}</td>
                            <td>{icon(item.mitraValue, item.id)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </>
            )}
            {props.history && (
              <>
                <table className="table w-full">
                  <thead>
                    <tr>
                      <th>PEMA</th>
                      <th></th>
                      <th>Partner</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {availability.map((item) => {
                      return (
                        <>
                          {item.id !== "minat_kerjasama" && (
                            <tr id={item.id} className="hover">
                              <td>{item.name}</td>
                              <td>{icon(item.pemaValue, item.id)}</td>
                              <td>{item.name}</td>
                              <td>{icon(item.mitraValue, item.id)}</td>
                            </tr>
                          )}
                          {item.id === "minat_kerjasama" && (
                            <div className="mt-4 pl-3">
                              <h1 className="font-bold">{item.name}</h1>
                              <p className="mt-1">{item.mitraValue}</p>
                            </div>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </table>
              </>
            )}
          </>
          {!isAdmin && !props.history && ketersediaanContent()}
          {!isAdmin && props.history && historyContent()}
        </ScrollPanel>
      </Dialog>
    </div>
  );
}
