import Sidebar from "../../components/sidebar/Sidebar";
import React from "react";
import { Navigate } from "react-router-dom";
import Navbar from "../../components/navbar/Navbar";
import Table from "../../components/datatable/DataTable";

const Portofolio = () => {
  const accessToken = localStorage.getItem("access_token");
  const url = "https://bendmitra.ptpema.co.id/api/portofolio";
  const columns = [
    { field: "nama_proyek", header: "Project Name" },
    { field: "tahun_mulai", header: "Start Year", type: "year" },
    { field: "tahun_selesai", header: "Year Finished", type: "year" },
    { field: "owner", header: "Owner" },
    { field: "nilai_po", header: "PO Score", type: "integer" },
    { field: "galery", header: "Gallery" },
  ];

  if (localStorage.getItem("role") !== "Mitra" && accessToken) {
    return <Navigate to="/admin" />;
  }

  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }

  return (
    <div className="container">
      <Sidebar />
      <main>
        <Navbar title="Portfolio" />
        <div className="content">
          <div className="task-action"></div>
          <div className="task-list">
            <Table id={"id_portofolio"} sendFiles={true} action={true} edit={true} columns={columns} url={url}></Table>
          </div>
        </div>
      </main>
    </div>
  );
};
export default Portofolio;
