import Sidebar from "../../components/sidebar/Sidebar";
import Navbar from "../../components/navbar/Navbar";
import { Navigate } from "react-router-dom";
import ViewData from "../../components/dataview/DataView";

const AdminDashboard = () => {
  const accessToken = localStorage.getItem("access_token");
  const urlProject = "https://bendmitra.ptpema.co.id/api/project";
  const urlKerjasama = "https://bendmitra.ptpema.co.id/api/kerjasama";

  if (accessToken === "null" || !accessToken) {
    return <Navigate to="/login" />;
  }
  if (localStorage.getItem("role") !== "Admin" && accessToken) {
    return <Navigate to="/" />;
  }

  return (
    <div className="container">
      <Sidebar />
      <main>
        <Navbar title="Projects Open" />
        <div className="content">
          <ViewData urlProject={urlProject} urlKerjasama={urlKerjasama} dashboard={true} />
        </div>
      </main>
    </div>
  );
};
export default AdminDashboard;
